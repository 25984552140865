<template>
  <v-row class="ma-0 pa-0 fill-height">
    <v-col lg="6" class="d-flex align-center justify-center primary flex-column elevation-6" v-if="$vuetify.breakpoint.lgAndUp">
      <h1 class="font-weight-bold white--text display-3">Lebensmittel</h1>
      <h2 class="font-weight-bold white--text display-1">Warenkunde</h2>
    </v-col>
    <v-col cols="12" lg="6" class="d-flex align-center justify-center">
      <validation-observer ref="observer" v-slot="{ passes }" :style="$vuetify.breakpoint.lgAndUp ? 'width: 80%' : 'width: 100%'">
        <v-form @submit.prevent="passes(submit)">
          <v-card flat tile>
            <v-card-title class="text-h4 font-weight-bold" v-html="$t('forms.login.title')"></v-card-title>
            <v-card-subtitle class="caption mt-4">{{ $t('forms.login.subtitle') }}</v-card-subtitle>
            <v-card-text>
              <validation-provider rules="required" :name="$t('forms.login.fields.email.name')" v-slot="{ errors }">
                <v-text-field class="my-6" v-model="form.email" type="email" :error-messages="errors"
                              :placeholder="$t('forms.login.fields.email.placeholder')"
                              dense :label="$t('forms.login.fields.email.label')"></v-text-field>
              </validation-provider>
              <validation-provider rules="required" :name="$t('forms.login.fields.password.label')" v-slot="{ errors }">
                <v-text-field class="my-6" v-model="form.password" type="password" :error-messages="errors"
                              :placeholder="$t('forms.login.fields.password.label')"
                              dense :label="$t('forms.login.fields.password.label')"></v-text-field>
              </validation-provider>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn type="submit"
                     :loading="$store.getters.GET_LOADING"
                     min-width="30%" color="primary" tile>{{ $t('forms.login.submit') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-col>
  </v-row>
</template>
<script>

export default {
  name: 'Home',
  data () {
    return {
      form: {
        email: null,
        password: null
      }
    }
  },
  methods: {
    submit () {
      this.$store.dispatch('setLoading', true)
      this.$http.post('api/v2/login', this.form)
        .then(() => {
          switch (this.$store.getters.GET_USER.role) {
            case 'user':
              this.$router.push({ name: 'user.dashboard' })
              return
            case 'admin':
              this.$router.push({ name: 'admin.dashboard' })
          }
        })
        .finally(() => {
          this.$store.dispatch('setLoading', false)
        })
    }
  }
}
</script>
